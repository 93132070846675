<template>
  <div class="codiceDownloadReferto">
    <div class="row no-gutters">
      <div class="col-lg-10 offset-lg-1">
        <app-check-sms :service="this.service"  :target="this.target" :mdl="this.isMdl  "></app-check-sms>
      </div>
    </div>
  </div>
</template>
<script>
import AppCheckSms from "../components/organisms/AppCheckSms.vue";
export default {
  components: { AppCheckSms },
  name: "confermaCodSms",

  data() {
    return {
      registration: {
        firstName: "Giacomo",
        lastName: "Magnani",
        codiceFiscale: "mgngcm90c29c618x",
        straniero: false,
        birthDate: "1990-03-29T00:00:00+00:00",
        gender: "M",
        country: "Brescia",
        nationBorn: "Italia",
        cityBornFromTaxCode: "CHIARI",
        username: "mgngcm90c29c618x",
        email: "jack.magnakoki@gmail.com",
        tel: "3293978528",
        invoice: {
          data: {
            administrative: "Lombardia",
            city: "Palazzolo sull'Oglio",
            country: "Italia",
            countryCode: "it",
            county: "Brescia",
            name: "Via Luigi Pirandello 27",
            postcode: "25036",
            value:
              "Via Luigi Pirandello 27, Palazzolo sull'Oglio, Lombardia, Italia",
          },
        },
      },
    };
  },
  computed:{

    isMdl(){
      let mdl=this.$route.query.mdl;
      if (mdl==null)
        return false;
      return mdl==='true';
    },
    service() {
      let uri = window.location.href.split('?');
      if (uri.length == 2) {
        let vars = uri[1].split('&');
        let getVars = {};
        let tmp = '';
        vars.forEach(function (v) {
          tmp = v.split('=');
          if (tmp.length == 2)
            getVars[tmp[0]] = tmp[1];
        });

        if ('service' in getVars)
        {
          return getVars.service;
        }

      }
      return null;
    },
    target() {
      let uri = window.location.href.split('?');
      if (uri.length == 2) {
        let vars = uri[1].split('&');
        let getVars = {};
        let tmp = '';
        vars.forEach(function (v) {
          tmp = v.split('=');
          if (tmp.length == 2)
            getVars[tmp[0]] = tmp[1];
        });

        if ('goto' in getVars)
        {
          return getVars.goto;
        }

        if ('target' in getVars)
        {
          return getVars.target;
        }

      }
      return null;
    }
  },
  methods: {},
};
</script>
