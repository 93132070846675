<template>
  <div class="row no-gutters">
    <div class="col-12">
      <div class="row no-gutters">
        <div class="col-10 offset-1 col-lg-12 offset-lg-0">
          <div class="m-card text-center">
            <h1 class="o-heading o-heading--xl">
              {{ $t("codSms.title") }}
            </h1>
            <p>{{ $t("codSms.subtitle") }}<strong>{{starPhone()}}</strong></p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-10 offset-1 col-lg-4 offset-lg-4 pt-5 ">
      <div class="m-card ">
        <div class="m-card__img">
          <b-img-lazy
            src="../../assets/smsCheck.svg"
            alt="smsCheck"
          ></b-img-lazy>
        </div>
        <div class="m-card__form">
          <b-form-group
            id="cod_episodio"
            :label="this.$t('codSms.sblocco')"
            label-for="pin-input"
            class="m-input"
          >
            <b-form-input
              id="pin-input"
              v-model="data.pin"
              type="text"
              :state="this.checkPin(data)"
              :placeholder="this.$t('codSms.sblocco_placeholder')"
              required
            ></b-form-input>
          </b-form-group>

          <div class="d-flex justify-content-between align-items-center mt-2" v-if="stage>=1">
            <b-button @click="verificaPin()" class=" mb-2 text-light " variant="secondary" size="md" >{{
              $t("codSms.action_1")
            }}</b-button>
            <a v-if="showResend" @click="resendSms()" class="text-primary" :style="{ cursor: 'pointer'}"
              ><strong>{{ $t("codSms.action_2") }}</strong></a
            >
          </div>
        </div>
      </div>
    </div>

    <div class="col-10 offset-1 col-lg-4 offset-lg-4 pt-4 text-center" v-if="hasApp && enableApp">
      <a  class="text-primary"
        ><strong>{{ $t("codSms.action_0") }}</strong></a
      >
    </div>
  </div>
</template>

<script>
import store from '@/store/index';
import apiAxios from "@/libs/apiAxios";
import router from "@/router";
export default {
  name: "AppCheckSms",
  props:{
    service:{
      default:"fatref"
    },
    fullPage:{
      default:true
    },
    enableApp:{
      default:true
    },
    target:{
      default:null
    },
    mdl:{
      default:false
    }
  },
  created() {
    this.queryService();
  },
  data() {
    return {
      stage:0,
      seed:null,
      seedAck:null,
      hashAck:null,
      error:null,
      checking:false,
      passwordHide: "*********",
      ticks:20,

      data: [

      ],
    };
  },
  computed: {
    showResend:function () {
      return this.ticks<=0;
    },
    myService: function () {
      switch(this.service)
      {
        case "referti":
        case "fatture":
          return "fatref";
          case "mdl":
            return "mdl"
        default:
          return "";
      }

    },
    userdata: function () {
      return store.getters['dateUser/UserData'];
    },
    hasApp: function () {
      if (this.userdata == null)
        return false;

      if (this.userdata.services == null)
        return false;

      let servizio = null;
      switch (this.service) {
        case "referti":
          servizio = this.userdata.services.REFERTI;
          break;
        case "fatture":
          servizio = this.userdata.services.FATTURE;
          break;
        case "mdl":
          servizio = {pinAuth:true};
          break;
        default:
          break;
      }

      if (servizio == null)
        return false;

      return servizio.pinAuth;
    }
  },

  methods: {
    resendSms(){
      this.ticks=20;
      this.data.pin="";
      this.queryService()
    },
    verificaPin(){
      let pin="";
      let data=this.data;
      if ('pin' in data )
      {
        pin=data.pin;
      }
      if (pin=="")
        return ;
      if (pin.length==6)
      {
        let md5=require('md5');
        let checkHash=md5(this.seedAck+pin);
        if (checkHash==this.hashAck)
        {
          this.inviaPin(pin);
        }
        else {
          data.pin='';
        }

      }
    },
    inviaPin:function(pin)
    {
      var md5 = require('md5');
      const hash=md5(this.seed+pin);
      const data={
        hash:hash
      };
      apiAxios.post('user/verifyServicePin/'+this.otp,data)
          .then(response=>{
            const res=response.data.VerifyServicePinResult;
            this.smsPinCallback(res);
          })
          .catch(()=>{

            this.error=encodeURI("OTP non valido o scaduto");
            this.fail();
          });
    },
    smsPinCallback:function(success)
    {
      if (success)
      {
        this.checkAuth();
      }
      else {
        this.data.pin="";
      }
    },
    checkAuth()
    {
      if (this.checking)
        return;
      this.checking=true;
      apiAxios.get("user/otpPoll/"+this.otp)
          .then(response=>{
            const res=response.data.OtpPollResult;
            this.checking=false;
            switch(res.state)
            {
              case 1:
                if ('service_token' in res.payload) {
                  sessionStorage.setItem("security_token", res.payload.service_token);
                }
                this.success(res.payload);
                break;
              case 0:
                break;
              case -1:
                this.error="OTP Error"
                this.fail();
                break;
            }
          })
          .catch(error=>{
            console.log(error);
            this.checking=false;
            this.fail();
          });

    },
    checkPin(data){
      let pin="";
      if ('pin' in data )
      {
        pin=data.pin;
      }
      if (pin=="")
        return null;
      return (pin.length==6)?null:false;
    },
    async queryService(){
      const data={
        service:this.service,
        media:"sms"
      }
      let otp=null;

      await apiAxios.post("user/queryService",data)
        .then(response => {
          otp=response.data.QueryServiceResult;
          this.queryCallback(otp);
        })
        .catch(error => {console.log(error);this.fail("Servizio non permesso")})


    },
    queryCallback:function(data)
    {
      console.log(data);
      this.otp=data.otp;
      this.seed=('seed' in data)?data.seed:null;
      this.seedAck=('seedAck' in data)?data.seedAck:null;
      this.hashAck=('hashAck' in data)?data.hashAck:null;



      if (this.otp==null)
      {
        this.error="Invalid OTP";
        this.fail();
        return;
      }
      this.stage=1;

    },
    starPhone:function ()
    {
      if (this.userdata==null)
      {
        return null;
      }
      const phone=this.userdata.cell;
      return "*******"+phone.substring(phone.length-4);
    },
    fail()
    {
      this.stage=0;
      switch(this.service)
      {
        case "referti":
          router.push("/?fail="+this.error);
          return;
        case "fatture":
          router.push("/?fail="+this.error);
          return;
        default:
          this.$emit("failed")
          return "";
      }
    },
    subOne()
    {
      if (this.ticks<=0)
        return;
      let t=this.ticks;
      t--;
      if (t<0)
        t=0;
      this.ticks=t;
    },
    success(payload)
    {
      console.log(payload);
      router.push(this.target+(this.mdl?'?mdl=true':''));
 /*     switch(this.service)
      {
        case "referti":
          router.push("referti");
          return;
        case "fatture":
          router.push("fatture");
          return;
        default:
          this.$emit("unlocked")
          return "";
      }
*/
    },
    },
  mounted() {

      this.pollInterval = setInterval(this.subOne, 1000) //save reference to the interval
      setTimeout(() => {clearInterval(this.pollInterval)}, 12000000) //stop polling after an hour


  }

};
</script>
